(function($) {
	'use strict';
	console.log("Main JS");
	toastr.options = {
		"closeButton": true,
		"debug": false,
		"newestOnTop": true,
		"progressBar": true,
		"positionClass": "toast-top-full-width",
		"preventDuplicates": true,
		"onclick": null,
		"showDuration": "1500",
		"hideDuration": "1500",
		"timeOut": "7500",
		"extendedTimeOut": "5000",
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};
	jQuery.validator.setDefaults({
		validClass: "has-success",
		errorClass: "has-error",
		errorElement: "small",
		highlight: function(element, errorClass, validClass) {
			$(element).closest(".form-group").addClass(errorClass).removeClass(validClass);
		},
		unhighlight: function(element, errorClass, validClass) {
			$(element).closest(".form-group").addClass(validClass).removeClass(errorClass);
		},
		errorPlacement: function(error, element) {
			error.appendTo($(element).next(".help-block"));
		}
	});
	$.validator.methods.email = function(value, element) {
		return this.optional(element) || /[a-z]+@[a-z]+\.[a-z]+/.test(value);
	};
	$.validator.methods.phone = function(value, element) {
		return this.optional(element) || /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value);
	};
	// Call Me Form
	var $formCallMe = $('#formCallMe');
	var validatorCallMe = $formCallMe.validate({
		rules: {
			name: "required",
			company: "required",
			email: {
				required: true,
				email: true
			},
			phone: {
				required: true,
				phone: true
			}
		},
		messages: {
			name: "Mandatory Field.",
			company: "Mandatory Field.",
			email: {
				required: "Mandatory Field.",
				email: "Email don´t seems valid"
			},
			phone: {
				required: "Mandatory Field.",
				phone: "Phone Number don´t seems valid"
			}
		},
		submitHandler: function(form) {
			$.ajax({
				url: $formCallMe.attr('action'),
				type: 'POST',
				data: $formCallMe.serialize(),
				dataType: 'json',
				success: function(response) {
					if (response.result.code === 0) {
						toastr.success(response.result.message);
						validatorCallMe.resetForm();
					} else {
						toastr.error(response.result.message);
					}
				}
			});
		}
	});
	// Demo
	var $formDemo = $('#formDemo');
	var validatorDemo = $formDemo.validate({
		rules: {
			name: "required",
			company: "required",

			email: {
				required: true,
				email: true
			},
			phone: {
				required: true,
				phone: true
			},
			date: "required"
		},
		messages: {
			name: "Mandatory Field.",
			company: "Mandatory Field.",
			email: {
				required: "Mandatory Field.",
				email: "Email don´t seems valid"
			},
			phone: {
				required: "Mandatory Field.",
				phone: "Phone Number don´t seems valid"
			},
			date: "Mandatory Field."
		},
		submitHandler: function(form) {
			$.ajax({
				url: $formDemo.attr('action'),
				type: 'POST',
				data: $formCallMe.serialize(),
				dataType: 'json',
				success: function(response) {
					if (response.result.code === 0) {
						toastr.success(response.result.message);
						validatorDemo.resetForm();
					} else {
						toastr.error(response.result.message);
					}
				}
			});
		}
	});
	$('#demo-date').mask('00/00/0000').datepicker({
		maxViewMode: 1,
		daysOfWeekDisabled: "0,6",
		autoclose: true,
		todayHighlight: true
	}).on('changeDate', function(e) {
		validatorDemo.element( "#demo-date" );
	});
	// Ask Me form
	var $formAsk = $('#formAsk');
	var validatorAsk = $formAsk.validate({
		rules: {
			name: "required",
			company: "required",
			email: {
				required: true,
				email: true
			},
			phone: {
				required: true,
				phone: true
			},
			message: "required"
		},
		messages: {
			name: "Mandatory Field.",
			company: "Mandatory Field.",
			email: {
				required: "Mandatory Field.",
				email: "Email don´t seems valid"
			},
			phone: {
				required: "Mandatory Field.",
				phone: "Phone Number don´t seems valid"
			},
			message: "Mandatory Field."
		},
		submitHandler: function(form) {
			$.ajax({
				url: $formAsk.attr('action'),
				type: 'POST',
				data: $formAsk.serialize(),
				dataType: 'json',
				success: function(response) {
					if (response.result.code === 0) {
						toastr.success(response.result.message);
						validatorAsk.resetForm();
					} else {
						toastr.error(response.result.message);
					}
				}
			});
		}
	});
})(jQuery);
